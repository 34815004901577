import { keepPreviousData, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useHandleField from '../../components/form/model/main'
import ActionsView from '../../components/form/view/action-view'
import MainForm from '../../components/form/view/main-form'
import Loading from '../../components/general/Loading'
import Breadcrumbs from '../../components/toolbar/view'
import ModelService from '../../core/services/model-service/model-service'
import ViewServices from '../../core/services/view-service/view-service'
import useFormStore from '../../store/form'
import useHeaderStore from '../../store/header'
import { FIELD_TYPE } from '../../util/constant/field-type'
import { METHOD_TYPE, RELATION_TYPE } from '../../util/constant/method-type'
import { VIEW_TYPE } from '../../util/constant/view-type'
import { evalJSONContext, getSpecificationByFields, showErrorMessage, showSuccessMessage } from '../../util/util'

const FormView = () => {
  const [searchParams] = useSearchParams()
  const resModel = searchParams.get('model')
  const id = parseInt(searchParams.get('id'))
  const vid = searchParams.get('vid')
  const { lang } = useHeaderStore()
  const [onchangeData, setOnchangeData] = useState()
  const [objVal, setObjVal] = useState({})
  const [fieldChange, setFieldChange] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const { setViewDataStore } = useFormStore()
  const { rootContext } = useHeaderStore()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isFade, setIsFade] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  // fetch action data
  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}-${lang}`, vid, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })
  const actionData = actionReponse?.[0]
  const nameAction = actionData?.name
  const contextAction = actionData?.context ? evalJSONContext(actionData?.context) : {}

  // fetch list field have onchange api and related
  const { data: fieldsOnchange } = useQuery({
    queryKey: [`field-onchange-${resModel}`, resModel],
    queryFn: () => ModelService.getFieldWithOnchange({ model: resModel }),
    refetchOnWindowFocus: false,
  })

  const { data: viewResponse } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, actionData?.id, lang],
    queryFn: () => ViewServices.getFieldView({
      resModel: actionData?.res_model,
      views: [...actionData?.views, [actionData?.search_view_id, 'search']],
      context: rootContext
    }),
    enabled: !!actionData,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })
  const viewData = viewResponse
  const toolbar = viewResponse?.views?.tree?.toolbar
  const fields = viewResponse?.views?.form?.fields
  const oeTitle = viewResponse?.views?.form?.oe_title || []
  const headerFiled = viewResponse?.views?.form?.header ? viewResponse?.views?.form?.header.filter((comp) => comp?.type_co === 'field') : []

  const specification =
    viewResponse &&
    getSpecificationByFields(
      [...viewResponse?.views?.form?.fields, ...oeTitle, ...headerFiled, ...viewResponse?.views?.form?.tabs],
      {},
      viewData,
      resModel
    )

  const initVal = ModelService.toDataJS(onchangeData, viewData, resModel)
  const methods = useForm({
    mode: 'onBlur',
    values: initVal,
  })

  const formValues = methods.watch()
  console.log("🚀 ~ FormView ~ formValues:", formValues)
  const { dirtyFields } = methods.formState

  const storeData = async () => {
    try {
      if (actionReponse && actionReponse.length) {
        sessionStorage.setItem('actionData', JSON.stringify(actionReponse[0]))
      }
      sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      setViewDataStore(viewResponse)
    } catch (err) {
      console.log(err)
    }
  }

  // useEffect(() => {
  //   // const isEqual = JSON.stringify(originData.current) === JSON.stringify(formValues);
  //   const handleBeforeUnload = (event) => {
  //     if (isDirty) {
  //       if (event) {
  //         event.preventDefault();
  //         event.returnValue = '';
  //       }
  //       else {
  //         window.confirm('Do you wann leave? Changes not saved yet!')
  //       }
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     handleBeforeUnload()
  //   };

  // }, []);

  // useEffect(() => {
  //   setIsDirty(methods.formState.isDirty);
  // }, [methods.formState.isDirty])

  //store data 
  useEffect(() => {
    storeData()
  }, [viewResponse])

  // filter field change
  const filterFieldDirty = (id, viewData, formValues, dirtyFields, resModel, defaultData) => {
    const data = id ? { ...dirtyFields } : { ...formValues }
    for (const key in data) {
      if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY)) {
        const lineData = [];
        (formValues[key] ??= []).forEach((itemData, index) => {
          if (!itemData?.id) {

            lineData.push([METHOD_TYPE.CREATE, `virtual_${index}`, filterFieldDirty(
              itemData?.id,
              viewData,
              itemData,
              {},
              viewData?.models?.[resModel]?.[key]?.relation
            )])
          } else {
            dirtyFields[key].forEach((itemDirty) => {
              lineData.push([
                METHOD_TYPE.UPDATE,
                itemData?.id,
                filterFieldDirty(
                  itemData?.id,
                  viewData,
                  itemData,
                  itemDirty,
                  viewData?.models?.[resModel]?.[key]?.relation
                ),
              ])
            })
          }
        });
        (defaultData[key] ??= []).forEach((item) => {
          if (!(formValues[key] ??= [])?.find((itemData) => itemData?.id === item?.id)) {
            lineData.push([METHOD_TYPE.DELETE, item?.id, item])
          }
        });

        data[key] = lineData
      } else if (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY) {
        const lineData = [];

        (formValues[key] ??= []).forEach((itemData, index) => {
          lineData.push([RELATION_TYPE.NO_CHANGE, itemData?.id])
        })
        data[key] = lineData
      }
      else if (id) {
        data[key] = formValues?.[key]
      }
    }

    return data
  }


  // fetch onchange default form data
  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeReponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: specification,
        context: context,
        object: objVal,
        fieldChange: fieldChange,
      })
      return onchangeReponse?.value
    } catch (err) {
      console.log(err)
    }
  }

  // fetch detail data record
  const fetchDetailData = async () => {
    try {
      const dataResponse = await ModelService.getDetailData({
        model: resModel,
        ids: id ? [id] : [],
        specification: specification,
        context: contextAction,
      })
      if (dataResponse.length > 0) {
        return dataResponse[0]
      }

      return {}
    } catch (err) {
      console.log(err)
    }
  }

  // get default form value 
  const { isLoading: isLoadingFields, isPending, isFetched: isQueryFetched, isFetching, refetch: refetchDataForm } = useQuery({
    queryKey: [`formData-${resModel}-${id}`, id],
    queryFn: async () => {
      if (!id) {
        const onchangeResponse = await fetchOnchange({
          ids: id ? [id] : [],
          model: resModel,
          specification: specification,
          context: contextAction,
          object: objVal,
          fieldChange: fieldChange,
        });
        setOnchangeData(onchangeResponse)
      } else {
        const detailData = await fetchDetailData()
        setOnchangeData(detailData)
      }

      return {}
    },
    enabled: !!specification,
    refetchOnWindowFocus: false,
  })
  console.log("🚀 ~ FormView ~ isFetching:", isFetching)



  // handle submit form
  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const data = ModelService.parseORMOdoo(
        filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)
      )

      const response = await ModelService.saveForm({
        ids: id ? [id] : [],
        resModel: resModel,
        data: data,
        specification: specification,
        context: contextAction,
      })
      if (response && response.length > 0) {
        searchParams.set('id', response?.[0]?.id)
        const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
        navigate(_url)
      }
      setLoading(false)
      showSuccessMessage(!id ? `${t('create_success')}` : `${t('update_success')}`)
    } catch (error) {
      console.log(error)
      setLoading(false)
      showErrorMessage(!id ? `${t('create_fail')}` : `${t('update_fail')}`)
    }
  }

  useEffect(() => {
    if (!isFetching && !isPending) {
      setTimeout(() => {
        setIsFade(true)
      }, 100)
    }
  }, [isFetching, isPending])

  //handle onchange formValues
  const handleOnchange = (nameField, value) => {
    if (fieldsOnchange?.includes(nameField)) {
      const fetchData = async () => {
        const obj = {
          ...objVal,
          ...ModelService.parseORMOdoo(filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)),
          [nameField]: value
        }
        const fieldChange = { ...dirtyFields }
        // for (const key of Object.keys(obj)) {
        //   obj[key] = formValues[key]
        // }
        // obj[nameField] = value
        const dataOnchange = await fetchOnchange({
          id: id,
          model: resModel,
          specification: specification,
          context: contextAction,
          objVal: ModelService.parseORMOdoo(obj),
          fieldChange: [nameField]
        })

        const updatedData = { ...formValues, [nameField]: value, ...ModelService.toDataJS(dataOnchange) };
        // if (dataOnchange) {
        //   Object.keys(dataOnchange).forEach((key) => {
        //     if ((viewResponse?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewResponse?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
        //       updatedData[key] = (dataOnchange[key] ??= []).map((item) => {
        //         if (item?.length >= 3) {
        //           return ModelService.toDataJS(item[2], viewData, resModel)
        //         }
        //         return null
        //       })
        //     }
        //   })
        // }

        const dataOnchangeJS = ModelService.toDataJS(updatedData, viewData, resModel)
        if (dataOnchangeJS) {
          Object.keys(dataOnchangeJS).forEach((key) => {
            if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
              methods.setValue(key, (dataOnchange[key] ??= []).map((item) => {
                if (item?.length >= 3) {

                  return ModelService.toDataJS(item[2], viewData, resModel)
                }
                return null
              }))
            } else {
              methods.setValue(key, dataOnchangeJS[key])
            }
          })
        }
      }
      fetchData()
    }
  }

  //handle delete record
  const handleDelete = async () => {
    const result = window.confirm("Bạn có chắc chắn muốn xóa không?");
    if (result) {
      setLoadingDelete(true)
      try {
        await ModelService.deleteByForm({ ids: [id], model: resModel })
        setLoadingDelete(false)
        showSuccessMessage(`${t('delete_success')}`)
        navigate(`/list?vid=${vid}&model=${resModel}`);
      } catch (err) {
        setLoadingDelete(false)
        showErrorMessage(`${t('delete_fail')}`)
      }

    }
  }

  //get field items
  const { fieldList } = useHandleField({
    fields: (fields && oeTitle) && [...oeTitle, ...fields],
    viewData: viewData,
    resModel: resModel,
    onchangeData: formValues,
    handleOnchange: handleOnchange
  })

  return (
    <div className={`page-wrapper  ${isFade && "fade-in"}`} >
      <div className='content'>
        {
          ((!isLoadingFields && isQueryFetched)) ?
            <FormProvider {...methods}>
              <Breadcrumbs
                id={id}
                title={nameAction}
                subTitle={!id ? t('new') : formValues?.name || formValues?.id}
                viewData={viewData}
                actionData={actionData}
                dataToolbar={toolbar}
                vid={vid}
                model={resModel}
                isInForm={true}
                funcReset={methods.reset}
                loading={loading}
                loadingDelete={loadingDelete}
                onDelete={handleDelete}
                onSubmit={handleFormSubmit}
                nameActionSave={id ? t('update_button') : t('save')}
              />
              <form className='relative form' onSubmit={methods.handleSubmit(handleFormSubmit)}
              >
                <div className='flex flex-col gap-2 md:!gap-4 w-full'>
                  <ActionsView refetch={refetchDataForm} actions={viewData?.views?.form?.header} resModel={resModel} viewData={viewData} data={formValues} />
                  <MainForm
                    handleOnchange={handleOnchange}
                    fieldList={fieldList}
                    viewData={viewData}
                    onchangeData={formValues}
                    resModel={resModel}
                    context={actionData?.context}
                    title={nameAction}

                  />
                </div>
              </form>
            </FormProvider> :
            <Loading />
        }
      </div>
    </div >
  )
}

export default FormView
