import React from 'react'
import PropTypes from 'prop-types'
import useHandleField from '../model/main'

const GroupView = (props) => {
    const { fields, viewData, resModel, onchangeData, is_root_group, handleOnchange } = props

    const { fieldList } = useHandleField({ fields: fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData, handleOnchange: handleOnchange })

    return (
        <div className={`${is_root_group ? "grid col-span-2 grid-cols-2 gap-x-6" : "col-span-2 md:col-span-1"}`}>
            {fieldList}
        </div>)
}

GroupView.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    viewData: PropTypes.object,
    resModel: PropTypes.string,
    onchangeData: PropTypes.object,
    is_root_group: PropTypes.bool,
}

export default GroupView