import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { X } from 'react-feather'
import ActionService from '../../../../core/services/actions'
import { ClipLoader } from 'react-spinners'
import { showErrorMessage } from '../../../../util/util'

const ModalSmsMessage = ({ setIsOpenModal, id }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const [message, setMessage] = useState('')

    const handleClose = () => {
        setIsOpenModal(prev => ({ ...prev, status: false }))
    }

    const handleSubmit = async () => {
        try {
            const res = await ActionService.createRecordSmsTextMessage({ id, phone: data?.recipient_single_number, message })
            const res1 = await ActionService.sendSmsTextMessage({ id: res?.value?.id })
        } catch (error) {
            showErrorMessage(error?.message)
        } finally {
            onClose();
        }
    }

    const getData = async () => {
        setLoading(true)
        setError(null)
        try {
            const res = await ActionService.getDataSmsTextMessage({ ids: id })
            setData(res?.value)
        } catch (error) {
            console.log('error:', error)
            setError(error?.message)
            showErrorMessage(error?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [id])

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleClose} aria-labelledby="modal-title">
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto'>
                            <div className='relative z-[1] mx-auto my-[88px] p-4 flex flex-col gap-2 max-w-[1000px] transform rounded-3xl bg-[#FFF]'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-title" className='text-[20px] font-semibold'>
                                        Gửi tin nhắn văn bản SMS
                                    </div>
                                    <button onClick={handleClose} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>

                                {loading ? (
                                    <div className='flex justify-center py-6'>
                                        <ClipLoader size={35} color={"#123abc"} loading={loading} />
                                    </div>
                                ) : error ? (
                                    <div className='flex justify-center py-6 text-red-500'>
                                        {error}
                                    </div>
                                ) : (
                                    <>
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className='flex items-center gap-2 py-6'>
                                                <div className='text-4 font-medium'>Người nhận</div>
                                                {data?.recipient_single_description}
                                            </div>
                                            <div className='flex items-center gap-2 py-6'>
                                                {data?.recipient_single_number}
                                            </div>
                                        </div>

                                        <div className='flex items-start gap-4 py-6'>
                                            <div className='text-4 font-medium shrink-0'>Tin nhắn</div>
                                            <textarea
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder="0 ký tự, khớp với 0 SMS (GSM7)"
                                                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-indigo-200"
                                                rows="4"
                                            />
                                        </div>

                                        <div className='flex gap-3 w-full justify-end py-2'>
                                            <button
                                                onClick={handleSubmit}
                                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 transition duration-300"
                                            >
                                                Gửi SMS
                                            </button>
                                            <button
                                                onClick={handleClose}
                                                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
                                            >
                                                Đóng
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalSmsMessage