import React from 'react'
import useCheckbox from '../model/checkbox'
import useTableStore from '../../../store/table'
import TableFilter from './table-filter'

const TableHead = ({
  columns,
  dataSource,
  visibleOptionalFields,
  model,
  isForm,
  columnWidths,
  handleResize,
  ResizableHeader,
  tabIndex,
  type
}) => {
  const { selectedRowKeys, setSelectedRowKeys } = useTableStore()
  const { handleSelectAllChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
  const isAllSelected = selectedRowKeys?.length > 0 && selectedRowKeys.length === dataSource?.length && selectedRowKeys.every(rowItem => dataSource.some(dataItem => dataItem.id === rowItem));

  return (
    <thead>
      <tr style={{ userSelect: 'none', WebkitUserSelect: 'none' }} className='border-b-[1.5px] border-[#DEE7DD]'>
        <th
          className={`${isForm ? 'hidden' : ''} w-12 p-3 text-left font-medium uppercase text-gray-500`}
        >
          <input
            type='checkbox'
            checked={isAllSelected}
            onChange={handleSelectAllChange}
            className='ml-2 align-middle custom-checkbox'
          />
        </th>
        {columnWidths &&
          columns?.map((col, index) => {
            return (
              <ResizableHeader
                key={'table-head-' + index}
                onResize={handleResize(index)}
                width={columnWidths[index]}
                islast={index === columns?.length - 1}
                className={`column whitespace-nowrap p-3 text-left text-sm font-medium capitalize text-[#121212]`}
              >
                {col.title}
              </ResizableHeader>
            )
          })}

        {isForm && <td className='relative p-2 w-[35px] '></td>}
        {visibleOptionalFields?.length > 0 && (
          <th
            className={`column sticky right-0 z-50 whitespace-nowrap bg-white px-2 text-gray-500`}
          >
            <TableFilter model={model} visibleOptionalFields={visibleOptionalFields} tabIndex={tabIndex} type={type} />
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHead
