import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import ViewServices from '../../core/services/view-service/view-service'
import { } from '@tanstack/react-query'

const Many2ManyCheckbox = ({ relation, name, readonly, placeholder, required, invisible, methods, handleOnchange }) => {
  const { data: dataOfSelection } = useAsync(
    () => ViewServices.getSelectionItem({ model: relation }),
    `data_${relation}`
  )

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.name,
    })) || []

  if (!isForm) {
    return <span>test</span>
  } else {
    return (
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => (
          <div className='flex flex-col space-y-2'>
            {options.map((option) => (
              <label
                key={option.value}
                className='flex items-center gap-2 rounded-lg border border-gray-300 p-2 hover:bg-gray-100'
              >
                <input
                  type='checkbox'
                  value={option.value}
                  checked={field.value?.includes(option.value) || false}
                  onChange={(e) => {
                    const newValue = [...(field.value || [])]
                    if (e.target.checked) {
                      newValue.push(option.value)
                    } else {
                      const index = newValue.indexOf(option.value)
                      if (index > -1) {
                        newValue.splice(index, 1)
                      }
                    }
                    field.onChange(newValue)
                    handleOnchange(name, newValue)
                  }}
                  className='h-5 w-5 text-blue-600'
                  readOnly={readonly}
                  placeholder={placeholder}
                  required={!invisible && required}
                />
                <span className='text-gray-700'>{option.label}</span>
              </label>
            ))}
          </div>
        )}
      />
    )
  }
}

export default Many2ManyCheckbox
