import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import useAsync from '../../hooks/useAsync'
import ViewServices from '../../core/services/view-service/view-service'

const Many2OneButton = ({ relation, name, methods, readonly, placeholder, required, invisible }) => {
  const { data: dataOfSelection } = useAsync(
    () => ViewServices.getSelectionItem({ model: relation }),
    `data_${relation}`
  )

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val?.id,
      label: val?.name,
    })) || []

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => (
        <div className='rounded-lg bg-gray-100 p-2'>
          <div className='button-list flex overflow-x-auto'>
            {options.map((option) => (
              <button
                key={option.value}
                className={`m-1 flex min-w-max cursor-pointer items-center justify-center rounded-lg bg-white px-4 py-2 !text-gray-800 transition-colors duration-300 ease-in-out hover:bg-blue-100 ${field.value === option.value ? 'bg-blue-700 text-white' : ''
                  }`}
                onClick={() => {
                  if (readonly) return
                  field.onChange(option.value)
                }}
                disabled={readonly}
                title={placeholder}
                aria-required={!invisible && required}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    />
  )
}

export default Many2OneButton
