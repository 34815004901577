import { create } from 'zustand';

const useThemeStore = create((set) => ({
    layoutStyling: localStorage.getItem("layoutStyling") ?? "default",
    setLayoutStyling: (newLayoutStyling) => {
        localStorage.setItem('layoutStyling', newLayoutStyling)
        document.documentElement.setAttribute('data-layout-style', newLayoutStyling)
        set({ layoutStyling: newLayoutStyling });
    },
}))

export default useThemeStore
