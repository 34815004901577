import { useLocation, useNavigate } from 'react-router-dom'

const useToolBar = (vid, model, funcReset, isInForm) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location
    const baseUrl = pathname.replace(/^\/?(new-|update-)?/, '')

    const onClickAddNew = () => {
        // funcReset()
        navigate(`/form?vid=${vid}&model=${model}`)
    }

    const onClickBack = () => {
        navigate(`/list?vid=${vid}&model=${model}`);
    }

    const isShow = !(pathname === "/list")

    return {
        baseUrl,
        pathname,
        isShow,
        onClickAddNew,
        onClickBack
    }
}

export default useToolBar
