import { evalPartialContext } from '../core/domain/context'
import { Domain } from '../core/domain/domain'
import { evaluateExpr } from '../core/domain/py'
import { COMPONENT_TYPE } from './constant/componen-type'
import { FIELD_TYPE } from './constant/field-type'
import alertify from 'alertifyjs'

export const getSpecification = (viewData, resModel) => {
  if (viewData?.models?.[resModel] && resModel) {
    const modelValues = Object.keys(viewData.models[resModel])
    if (modelValues.length > 0) {
      const spectification = modelValues.reduce((acc, item) => {
        acc[item] = {}
        return acc
      }, {})
      return spectification
    } else {
      console.warn('modelValues is empty')
    }
  } else {
    console.warn('listView.models[resModel] or model is undefined')
  }
}

export const getSpecificationByFields = (fields = [], specification = {}, viewData, model, modelRoot) => {
  if (Array.isArray(fields)) {
    let spec = { ...specification }
    fields.forEach((field) => {
      if (!field?.type_co || (field?.name && field?.type_co === COMPONENT_TYPE.FIELD)) {
        if (viewData?.models?.[model]?.[field?.name]) {
          if (viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.ONE2MANY || viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.MANY2MANY) {
            const relation = viewData?.models?.[model]?.[field?.name]?.relation
            const modelRelation = viewData?.models?.[relation]

            if (modelRelation) {
              spec[field?.name] = {
                fields: {},
              }

              if (modelRoot && modelRoot === relation) {
                spec[field?.name].fields = { id: {} }
              } else {
                spec[field?.name].fields = getSpecificationByFields(Object.values(modelRelation), {}, viewData, relation, model)
              }
            } else {
              spec[field?.name] = {
                fields: {
                  id: {},
                  display_name: {},
                }
              }
            }
          } else if (viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.MANY2ONE) {
            spec[field?.name] = { field: {} }
          } else {
            spec[field?.name] = {}
          }
        } else if (field?.type_tab) {
          const specTab = getSpecificationByFields(field?.fields, spec, viewData, model)
          spec = { ...spec, ...specTab }
        }
      } else if (field?.type_co === COMPONENT_TYPE.GROUP) {
        const specGroup = getSpecificationByFields(field?.fields, spec, viewData, model)
        spec = { ...spec, ...specGroup }
      }
    })
    return spec
  } else {
    console.warn('fields is not array')
  }
}

export const formatCurrency = (amount, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
  })

  return formatter.format(amount).replaceAll(".", ",")
}

export function formattedPath(path) {
  const formattedPath = path.replace('/', '').charAt(0).toUpperCase() + path.slice(2)
  const splitFormattedPath = formattedPath?.split('-')
  const showCapitalizedPart = splitFormattedPath[1] ? capitalizeFirstLetter(splitFormattedPath[1]) : ''
  return {
    showCapitalizedPart,
    splitFormattedPath,
  }
}

export function capitalizeFirstLetter(str) {
  if (!str) return str // Kiểm tra nếu chuỗi rỗng
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const convertStringToArray = (str) => {
  try {
    // Replace single quotes with double quotes and remove parentheses
    let formattedStr = str.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']');

    // Use a regular expression to find and replace variable names with their values
    formattedStr = formattedStr.replace(/"(\w+)"/g, (match, p1) => {
      if (typeof window[p1] !== 'undefined') {
        return JSON.stringify(window[p1]);
      }
      return match;
    });

    return JSON.parse(formattedStr);
  } catch (error) {
    console.error("Invalid string format", error);
    return [];
  }
};


export const getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key)) || {};
}

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const parseInputString = (str) => {
  if (!str) return
  const items = str
    .slice(1, -1)
    .split(',')
    .map((item) => item.trim())
  return items.filter((item) => item).map((item) => item.replace(/[()'"]/g, ''))
}

export const clearBeforeToJson = (str) => {
  if (!str) return
  return str.replaceAll('"', "'")
}

export const convertDomainStringToArray = (str) => {
  const jsonString = str
    .replace(/\(/g, '[') // Replace "(" with "["
    .replace(/\)/g, ']') // Replace ")" with "]"
    .replace(/'/g, '"')
    .replace(/False/g, 'false')

  return jsonString
}

export const evalJSONContext = (_context, context = {}) => {
  try {
    return evalPartialContext(_context, context)
  } catch (err) {
    return null
  }
}

export const evalJSONDomain = (domain, context) => {
  try {
    if (context) {
      Object.keys(context)?.forEach((key) => {
        if (Array.isArray(context[key])) {
          const isTypeObject = context[key]?.every(item => typeof item === 'object' && item !== null && item?.id !== undefined)
          if (isTypeObject) {
            context[key] = context[key]?.map(item => item?.id)
          }
        }
      })
    }
    const value = evaluateExpr(domain, context)
    return value
  } catch (err) {
    try {
      const domainObject = new Domain(domain).toList(context)
      return domainObject
    } catch (err) {
      console.error(err)
      return []
    }
  }
}

export function convertStringEmptyToHyphen(str) {
  return str.replace(/\s+/g, '-').toLowerCase()
}

// Utility function to convert float to "HH:MM" format
export const convertFloatToTime = (floatValue) => {
  const hours = Math.floor(floatValue);
  const minutes = Math.round((floatValue - hours) * 60);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const convertTimeToFloat = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + minutes / 60;
};

export const isNumber = (value) => {
  return !isNaN(value)
};

export const isFloat = (value) => {
  return !isNaN(value) && value.toString().indexOf('.') != -1;
};

export const createPathImage = (id, model, name) => {
  return `${process.env.REACT_APP_DOMAIN}web/image/${model}/${id}/${name}`
}

export const extractContent = (htmlString) => {
  const match = htmlString.match(/>([^<]*)</);
  return match ? match[1] : "";
}

export const toQueryString = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
};

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    // Fallback for browsers that do not support navigator.clipboard
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.position = 'fixed' // Avoid scrolling to the bottom of the page in MS Edge.
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
    } finally {
      document.body.removeChild(textArea)
    }
  }
}
export const stringToColor = (name, id) => {
  const combined = name + id / 2;
  let hash = 0;

  for (let i = 0; i < combined.length; i++) {
    hash = combined.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash into an RGB color code within a narrower medium range (120-180)
  const r = (hash >> 16) & 0xFF;
  const g = (hash >> 8) & 0xFF;
  const b = hash & 0xFF;

  // Constrain each RGB component to be between 120 and 180 for a more medium tone
  const adjustedR = 120 + (r % 61); // 120 to 180
  const adjustedG = 120 + (g % 61); // 120 to 180
  const adjustedB = 120 + (b % 61); // 120 to 180

  return `#${adjustedR.toString(16).padStart(2, "0")}${adjustedG.toString(16).padStart(2, "0")}${adjustedB.toString(16).padStart(2, "0")}`;
};

// format vnd price function by new Int
export const formatVNDPrice = (price) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(price)
}

export const showSuccessMessage = (message) => {
  alertify.set('notifier', 'position', 'top-right')
  alertify.success(message)
}

export const showErrorMessage = (message) => {
  alertify.set('notifier', 'position', 'top-right')
  alertify.error(message)
}

export const formatDateTime = (date, time) => {
  const hours = Math.floor(time);
  const minutes = (time % 1) * 60;
  return `${date}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
};

export const getArrayValues = (obj) => {
  const result = [];
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      result.push(...obj[key]);
    }
  }
  return result;
};

export const getSubdomain = (url) => {
  const urlObj = new URL(url);
  const hostname = urlObj.hostname;
  const parts = hostname.split('.');

  // Assuming the domain is at least three parts (e.g., subdomain.domain.tld)
  if (parts.length > 2) {
    return parts[0]; // Return the first part as the subdomain
  }

  return null; // No subdomain found
};