import React, { useMemo } from 'react'
import StatusBar from '../../widget/status-bar/Statusbar'
import { useTranslation } from 'react-i18next'
import ButtonView from './button-view'

const ActionsView = ({ data, viewData, resModel, refetch }) => {
    const { t } = useTranslation()
    const actions = viewData?.views?.form?.header || []
    const stateData = useMemo(() => {
        return viewData?.models?.[resModel]?.state?.selection || []
    }, [resModel])


    return (data?.state || actions.length > 0) && (
        <div className='w-full items-center bg-white rounded-[10px] p-6 border border-[#F2F2F2] grid lg:grid-cols-2 md:grid-cols-1 grid-between gap-y-[15px]'>
            <div className='col-span-1'>
                <StatusBar statusOptions={stateData} name={''} defaultValue={data?.state} />
            </div>

            <div className='col-span-1 lg:justify-end md:justify-start flex gap-2 flex-wrap'>
                {actions?.map((action, index) => {
                    if (data && action?.type_co === 'button') return <ButtonView refetch={refetch} key={index} resModel={resModel} dataForm={data} action={action} context={data} />
                })}
            </div>
        </div>)
}

export default ActionsView
