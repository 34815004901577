import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { SidebarContext } from '../util/context/SidebarContext'

const cache = new Map()

const useAsync = (asyncFunction, cacheKey) => {
  const [data, setData] = useState(() => cache.get(cacheKey))
  const [loading, setLoading] = useState(!cache.has(cacheKey))
  const [error, setError] = useState(null)
  const { isUpdate, setIsUpdate } = useContext(SidebarContext)
  const dataRef = useRef(data)
  const isFirstMount = useRef(true)
  const prevIsUpdateRef = useRef()

  const fetchData = useCallback(
    async (force = false) => {
      if (!force && cache.has(cacheKey)) {
        setData(cache.get(cacheKey))
        //   setLoading(false);
        return
      }
      setLoading(true)
      try {
        const res = await asyncFunction()
        if (JSON.stringify(res) !== JSON.stringify(dataRef.current)) {
          setData(res)
          dataRef.current = res
          cache.set(cacheKey, res)
          // console.log("Data updated and cached");
        } else {
          console.log('Data unchanged')
        }
        setError(null)
      } catch (err) {
        console.error('Error fetching data:', err)
        setError(err)
        setData(null)
      } finally {
        setLoading(false)
      }
    },
    [asyncFunction, cacheKey]
  )

  useEffect(() => {
    if (isFirstMount.current && !isUpdate) {
      fetchData()
      isFirstMount.current = false
    } else if (prevIsUpdateRef.current !== isUpdate) {
      fetchData(true)
      setIsUpdate(false)
    }
    prevIsUpdateRef.current = isUpdate
  }, [isUpdate, fetchData])

  const refetch = useCallback(() => {
    return fetchData(true)
  }, [fetchData])

  const updateData = useCallback(
    (newData) => {
      setData(newData)
      cache.set(cacheKey, newData)
    },
    [cacheKey]
  )

  return {
    data,
    error,
    loading,
    setData: updateData,
    refetch,
    isUpdate,
    setIsUpdate,
  }
}

export default useAsync
