import React from 'react'
import { Filter, Layers } from 'react-feather'
import { IoCheckmark } from 'react-icons/io5'
import useSearch from '../model/search'
import { useTranslation } from 'react-i18next'
import useSearchStore from '../../../store/search'

const PopupFilter = () => {
  const { t } = useTranslation()
  const { handleAddTagSearch, removeSelectedTag } = useSearch()
  const { filterBy, setFilterBy, groupBy, setGroupBy } = useSearchStore()

  return (
    <div className='popup-filter absolute right-0 top-[calc(100%_+_3px)] z-20 w-auto min-w-full overflow-x-auto rounded-lg border bg-white shadow-lg'>
      <div className='grid grid-cols-2 py-3'>
        <div className='w-full flex-1 border-r border-[#dee2e6] px-3'>
          <div className='flex w-fit items-center justify-start gap-2 px-3 py-1'>
            <Filter size={16} className='filter-icon h-4 w-4' />
            <span className='font-bold text-[#212529]'>{t("filter_by")}</span>
          </div>
          {filterBy?.reduce((acc, item, index, array) => {
            const prevItem = array[index - 1];
            const isDifferentGroup = prevItem && prevItem?.group_index !== item?.group_index;
            const isExist = item?.active

            if (isDifferentGroup) {
              acc.push(<hr key={'separator-' + index} className='my-2' />);
            }

            acc.push(
              <button
                key={'filter-' + index}
                className={`w-full flex items-center gap-2 bg-white px-3 py-1 text-left ${isExist ? "font-semibold" : 'hover:!bg-[rgba(0,0,0,0.08)]'}`}
                onClick={() => {

                  const newFilterBy = filterBy.map((tag) => tag?.name === item?.name ? { ...tag, active: !tag?.active } : tag);
                  setFilterBy(newFilterBy);

                  if (isExist) {
                    removeSelectedTag(item.group_index, item)
                    return
                  }
                  handleAddTagSearch({
                    title: <Filter size={16} className='h-4 w-4' />,
                    name: item?.name,
                    value: item?.string,
                    icon: <Filter />,
                    domain: item?.domain,
                    groupIndex: item?.group_index
                  });
                }}
              >
                {isExist && <IoCheckmark />}
                {item?.string ?? item?.help}
              </button>
            );

            return acc;
          }, [])}
        </div>
        <div className='w-full px-3'>
          <div className='flex w-fit items-center justify-start gap-2 px-3 py-1'>
            <Layers size={16} className='layer-icon h-4 w-4' />
            <span className='font-bold text-[#212529]'>{t("group_by")}</span>
          </div>

          {groupBy?.map(
            (item, index) => {
              const isExist = item?.active
              if (!item.string) return

              return (
                <button
                  onClick={() => {
                    const formatArray = groupBy.map((tag) => tag?.name === item?.name ? { ...tag, active: !tag?.active } : tag);
                    setGroupBy(formatArray)

                    handleAddTagSearch({
                      title: <Layers size={16} className='h-4 w-4' />,
                      name: item?.name,
                      value: item?.string,
                      icon: <Layers />,
                    })
                  }}
                  key={'filter-' + index + 1}
                  className={`w-full flex items-center gap-2 bg-white px-3 py-1 text-left ${isExist ? "font-semibold" : 'hover:!bg-[rgba(0,0,0,0.08)]'}`}
                >
                  {isExist && <IoCheckmark />}
                  {item?.string}
                </button>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}

export default PopupFilter
