import React, { useEffect, useMemo, useState } from 'react';
import useTabModel from '../model/tab';
import useHandleField from '../model/main';
import TreeViewForm from './tree-view-form';

const TabsView = ({ viewData, onchangeData, resModel, context, handleOnchange, title }) => {
  const [tabFocus, setTabFocus] = useState()

  const tabsData = useMemo(() => {
    return useTabModel(viewData, onchangeData, resModel)
  }, [viewData, onchangeData, resModel]);

  useEffect(() => {
    if (!tabFocus && tabsData && tabsData.length > 0) {
      setTabFocus(0)
    }
  }, [tabsData])

  const getViewInTab = (tab) => {
    if (tab?.type_tab === "form") {
      const { fieldList } = useHandleField({ fields: tab?.fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData, handleOnchange: handleOnchange })
      return (
        <>
          {fieldList}
        </>
      );
    } else if (tab?.type_tab === "tree") {
      return <TreeViewForm handleOnchange={handleOnchange} onchangeData={onchangeData} viewData={viewData} resModel={resModel} tab={tab} context={context} title={title} />
    }
  }

  return (
    <div>
      <div style={{
        scrollbarWidth: "none"
      }} className='w-full flex border-b border-[#EEEEEE] mb-2 overflow-x-auto'>
        {tabsData?.map((item, index) => {
          return <button key={index} onClick={() => setTabFocus(index)} type='button'
            className={`p-4 border-b-2 whitespace-nowrap ${tabFocus === index ? '!border-primary font-medium text-[#121212]' : 'border-transparent text-[#525252] font-normal'}`}>{item?.string}</button>
        })}
      </div>
      {tabsData?.map((tab, index) => {
        return (
          <div key={index} className={`${tabFocus === index ? 'block' : 'hidden'}`}>
            {
              getViewInTab({ ...tab, index: index })
            }
          </div>
        )
      })}
    </div>
  )
}

export default TabsView
