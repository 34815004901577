import React, { Fragment, useEffect } from 'react'
import { X } from 'react-feather'
import { useSearchParams } from 'react-router-dom'
import useTable from '../../table/model/table'
import { getSpecificationByFields } from '../../../util/util'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useTableStore from '../../../store/table'
import useFormStore from '../../../store/form'
import useHeaderStore from '../../../store/header'
import ModelService from '../../../core/services/model-service/model-service'
import ViewServices from '../../../core/services/view-service/view-service'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import TableView from '../../table/view/table-view'
import Search from '../../toolbar/view/search'
import Pagination from '../../general/Pagination'

const Many2ManyView = ({ title, setIsModal, relation, domain, context, tab, append }) => {
    const [searchParams] = useSearchParams()
    const vid = searchParams.get('vid')
    const model = searchParams.get('model')
    const { lang } = useHeaderStore()
    const actionData = JSON.parse(sessionStorage.getItem("actionData")) ?? ""

    const { fields, setFields, domainTable, setDomainTable, page, setPage } = useTableStore()
    const { setViewDataStore } = useFormStore()

    const { data: viewResponse } = useQuery({
        queryKey: [`view-${relation}-${vid}`, lang],
        queryFn: () => ViewServices.getFieldView({
            resModel: relation, views: [
                [false, 'list'],
                [false, 'tree']
            ], lang: lang
        }),
        refetchOnWindowFocus: false
    })

    const viewData = viewResponse

    const specification = viewResponse && getSpecificationByFields([...viewResponse?.views?.tree?.fields, ...tab?.fields], {}, viewData, relation)

    const fetchData = async () => {
        try {
            setDomainTable(domain)
            setViewDataStore(viewResponse)
            const modalData = viewResponse?.views?.tree?.fields.map((field) => ({
                ...viewResponse?.models?.[model]?.[field?.name],
                ...field
            }))

            if (!fields?.[`${vid}_${relation}_popupmany2many`] && modalData) {
                setFields({ ...fields, [`${vid}_${relation}_popupmany2many`]: modalData })
            }
            setPage(0)

        } catch (err) {
            console.log(err);
        }
    }
    const { data: dataResponse } = useQuery({
        queryKey: [`view-${relation}-${vid}`, specification, domainTable, page],
        queryFn: () => ModelService.getAll({ resModel: relation, specification: specification, domain: domainTable, offset: page * 10 }),
        enabled: !!specification && !!relation && !!domain,
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    })

    useEffect(() => {
        fetchData()
    }, [viewResponse, fields])

    const { dataSource, columns, visibleOptionalFields } = useTable({
        model: relation,
        fields: fields?.[`${vid}_${relation}_popupmany2many`] || viewResponse?.views?.tree?.fields,
        data: dataResponse?.records,
        dataModel: viewData?.models?.[relation],
        context: context,
        type: "many2many"
    });

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={() => setIsModal(false)}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto px-6 flex justify-center items-center'>
                            <div className='relative z-[1] p-4 flex flex-col gap-2 max-w-full lg:max-w-[1000px] transform rounded-3xl bg-[#FFF]'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div className='text-[20px] font-semibold'>
                                        Thêm: {title}
                                    </div>
                                    <button onClick={() => setIsModal(false)}>
                                        <X size={20} />
                                    </button>
                                </div>

                                <div className='grid grid-cols-3 gap-2 items-center py-2'>
                                    <div className='col-span-3 sm:col-span-2 flex-1'>
                                        <Search actionData={actionData} viewData={viewResponse} model={relation} />
                                    </div>
                                    <Pagination
                                        className="pagination-bar col-span-3 sm:col-span-1 justify-end flex-1"
                                        currentPage={page}
                                        totalCount={dataResponse?.length ?? 0}
                                        pageSize={10}
                                        onPageChange={page => setPage(page)}
                                        displayPageNumberDots={false}
                                    />

                                </div>
                                <TableView
                                    model={relation}
                                    dataSource={dataSource}
                                    dataLength={dataResponse?.length}
                                    columns={columns}
                                    visibleOptionalFields={visibleOptionalFields}
                                    type="many2many"
                                    tabIndex={tab?.index}
                                    append={append}
                                />
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default Many2ManyView