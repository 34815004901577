import { checkDomain } from "../../../core/domain/domain";

const useTabModel = (viewData, onchangeData, model) => {
    const tabsData = viewData?.views?.form?.tabs?.filter((val) => {
        if (!val) return null;
        const hide = checkDomain(onchangeData, val.invisible);
        if (!hide) {
            return val
        }
        return false
    }) || [];

    return tabsData
};

export default useTabModel