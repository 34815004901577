import React from 'react';
import PropTypes from 'prop-types';
import TabsView from './tabs-view';
import PaidBadged from '../../widget/PaidBadged';

const MainForm = (props) => {
    const { fieldList, viewData, onchangeData, resModel, context, handleOnchange, title } = props

    return (
        <div className={`px-6 relative py-8 rounded-[10px] border border-[#DEE7DD] shadow-[0_1px_8px_0px_rgba(82,88,102,0.06)] bg-white flex flex-col gap-8 min-h-20 w-full`}>
            {onchangeData?.payment_state === 'paid' && <PaidBadged />}
            <div className={`w-full grid grid-cols-2 transition-all duration-1000`}>
                {fieldList}
            </div>
            {viewData?.views?.form?.tabs && <TabsView handleOnchange={handleOnchange} viewData={viewData} onchangeData={onchangeData} resModel={resModel} context={context} title={title} />}
        </div>
    )
}

MainForm.propTypes = {
    name: PropTypes.string,
    fieldList: PropTypes.array,
    active: PropTypes.bool,
}

export default MainForm