import { create } from 'zustand'

const useHeaderStore = create((set) => ({
  lang: 'vi_VN',
  uid: undefined,
  companies: [],
  rootContext: { lang: 'vi_VN' },
  setLang: (lang) => set(() => ({ lang: lang })),
  setRootContext: (context) => set((prev) => ({ rootContext: { ...prev['rootContext'], ...context } })),
  setCompanies: (companyValues) => set(() => ({ companies: companyValues })),
  setUid: (uid) => set(() => ({ uid: uid })),
}))

export default useHeaderStore
