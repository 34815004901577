import React from 'react'
import useSearch from '../../model/search'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import useSearchStore from '../../../../store/search'
import { useTranslation } from 'react-i18next'

const SearchList = () => {
    const { searchString, setSearchString, hoveredIndexSearchList, setSelectedTags, selectedTags, tableHead: searchList } = useSearchStore(state => state)
    const { handleAddTagSearch, handleMouseEnter, handleMouseLeave } = useSearch()
    const { t } = useTranslation()

    const searchPopupRef = useClickOutside(() => {
        setSearchString('')
    })

    return (
        searchString !== "" &&
        <div
            ref={searchPopupRef}
            className='absolute left-0 right-0 top-[calc(100%_+_5px)] z-20 w-full overflow-x-auto rounded-[0.25rem] border bg-white shadow-lg'
        >
            {
                searchList.map((searchItem, index) => (
                    <button
                        key={'header-' + index + 1}
                        onClick={() => {
                            handleAddTagSearch({
                                title: searchItem?.title,
                                name: searchItem?.name,
                                value: searchString,
                                domain: searchItem?.filter_domain,
                                operator: searchItem?.operator
                            })
                        }
                        }
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        className={`block w-full cursor-pointer whitespace-nowrap p-2 px-3 py-1 text-left !duration-0 hover:!bg-[rgba(0,0,0,0.08)] hover:text-inherit ${hoveredIndexSearchList === index ? 'bg-[rgba(0,0,0,0.08)]' : 'bg-white'
                            }`}
                    >
                        {t("search")} <b>{searchItem?.title}</b> {t("for")}:{' '}
                        <span className='custom-input-result font-bold italic'>{searchString}</span>
                    </button>
                ))
            }
        </div>
    )
}

export default SearchList
