import React from 'react';

const InputField = ({
  type,
  name,
  methods,
  readonly,
  placeHolder,
  required,
  savedDataDetail = {},
  hasBorder = true,
  invisible
}) => {
  const formProps = methods ? methods.register(name) : {}

  return (
    <input
      {...formProps}
      type={type}
      readOnly={readonly}
      placeholder={placeHolder}
      required={!invisible && required}
      style={{ borderColor: hasBorder ? '#E4E4E7' : 'transparent' }}
      className='h-fit w-full rounded-lg border-[1px] border-solid bg-[#fff] p-2'
    />
  )
}

export default InputField
