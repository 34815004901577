import React from 'react'

const PaidBadged = () => {
  return (
    <div className='absolute z-10 right-4 top-4'>
      <img src='assets/img/paid.png' alt='paid' />
    </div>
  )
}

export default PaidBadged