import React, { memo, useMemo } from 'react'
import Option from './Option'

const StatusBar = ({ statusOptions, defaultValue }) => {
  const memoizedStatusOptions = useMemo(() => statusOptions, [statusOptions])

  return (
    <div className='flex items-center'>
      {memoizedStatusOptions?.map((option, index) => {
        const value = option?.[0] === defaultValue

        return (
          <Option
            key={option[0]}
            isActive={value}
            label={option[1]}
            isFirst={index === 0}
            isLast={index === memoizedStatusOptions?.length - 1}
          />
        )
      })}
    </div>
  )
}

export default memo(StatusBar)

// const statusOptions1 = [
//   ["backlog", "Backlog"],
//   ["todo", "To Do"],
//   ["inprocess", "In Process"],
//   ["done", "Done"],
// ]