import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { X } from 'react-feather'
import { ClipLoader } from 'react-spinners'

const ModalConvertReceipt = ({ id, setIsOpenModal }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setIsOpenModal(prev => ({ ...prev, status: false }))
    }

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleClose} aria-labelledby="modal-title">
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto'>
                            <div className='relative z-[1] mx-auto my-[88px] p-4 flex flex-col gap-2 max-w-[1000px] transform rounded-3xl bg-[#FFF]'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-title" className='text-[20px] font-semibold'>
                                        Lỗi xác nhận
                                    </div>
                                    <button onClick={handleClose} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>

                                {loading ? (
                                    <div className='flex justify-center py-6'>
                                        <ClipLoader size={35} color={"#123abc"} loading={loading} />
                                    </div>
                                ) : error ? (
                                    <div className='flex justify-center py-6 text-red-500'>
                                        {error}
                                    </div>
                                ) : (
                                    <>
                                        <div className='flex gap-4 py-6'>
                                            <p className='text-[20px]'>Bạn không thể chuyển đổi loại chứng từ đã vào sổ.</p>
                                        </div>

                                        <div className='flex gap-3 w-full justify-end py-2'>
                                            <button
                                                onClick={handleClose}
                                                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
                                            >
                                                Đóng
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalConvertReceipt