import React from 'react'
import { useTranslation } from 'react-i18next'
import useUserSubmit from '../../hooks/useUserSubmit'
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { SidebarContext } from '../../util/context/SidebarContext'
import Loading from '../../components/general/Loading'
import ImageWithBasePath from '../../components/general/ImageCustom'
import ButtonLoading from '../../components/general/ButtonLoading'
const Profile = () => {
  let navigate = useNavigate()
  const { t } = useTranslation()
  const { isLoading } = useContext(SidebarContext)
  const {
    onUpdateProfile,
    handleSubmit,
    register,
    getValues,
    imageSrc,
    setImageSrc,
    onImageUpload,
    imageUpload,
    setImageUpload,
    colorPalette,
  } = useUserSubmit()

  const handleCancel = async (e) => {
    e.preventDefault()
    navigate('/sales-dashboard')
  }

  const handleUploadImage = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = async () => {
        setImageSrc(reader.result)
      }
      reader.readAsDataURL(file)
    }
    setImageUpload(file)
  }

  if (isLoading) return <Loading />

  return <div className='page-wrapper'>
    <div className='page-header'>
      <div className='page-title mt-4'>
        <h4>{t('profile_title')}</h4>
        <h6>{t('profile_subtitle')}</h6>
      </div>
    </div>
    <div className='card'>
      <div className='card-body p-4'>
        <div className='profile-set'>
          <div
            style={{
              background: `linear-gradient(90deg,${colorPalette} 0%, #FFFFFF 100%)`,
            }}
            className='profile-head'
          ></div>
          <div className='profile-top'>
            <div className='profile-content'>
              <div className='profile-contentimg'>
                <img
                  src={imageSrc || 'assets/img/customer/customer5.jpg'}
                  style={{ aspectRatio: '1' }}
                  alt='img'
                  id='blah'
                />
                <div className='profileupload'>
                  <input type='file' id='imgInp' onChange={(e) => handleUploadImage(e)} />
                  <Link to='#'>
                    <ImageWithBasePath src='assets/img/icons/edit-set.svg' alt='img' />
                  </Link>
                </div>
              </div>
              <div className='profile-contentname'>
                <h2>{getValues('name') || 'Username'}</h2>
                <h4>{t('user_profile_note')}</h4>
              </div>
            </div>
            <div className='ms-auto'>
              <ButtonLoading
                loading={isLoading}
                className={'btn btn-submit me-2'}
                disable={!imageUpload}
                content={'save'}
                func={() => onImageUpload(imageUpload)}
              />
              <button
                className='btn btn-cancel'
                disabled={!imageUpload}
                onClick={() => {
                  setImageSrc(getValues('picture')), setImageUpload(false)
                }}
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-sm-12'>
            <div className='input-blocks'>
              <label className='form-label'>{t('name')}</label>
              <input type='text' placeholder={t('placeholder_username')} {...register('name')} />
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='input-blocks'>
              <label>Email</label>
              <input type='text' placeholder={t('placeholder_email')} {...register('email')} />
            </div>
          </div>
          <form onSubmit={handleSubmit(onUpdateProfile)}>
            <div className='col-12'>
              <ButtonLoading loading={isLoading} className={'btn btn-submit me-2'} content={'submit'} />
              <button onClick={(e) => handleCancel(e)} className='btn btn-cancel text-[#0B701D]'>
                {t('cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Profile
