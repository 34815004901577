const useCheckbox = (setSelectedRowKeys, selectedRowKeys, newData) => {
    const onSelectChange = (id) => {
        const newSelectedRowKeys = selectedRowKeys.includes(id)
            ? selectedRowKeys.filter((key) => key !== id)
            : [...selectedRowKeys, id];
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleCheckboxChange = (event, id) => {
        event.stopPropagation();
        onSelectChange(id);
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allRowKeys = Array.isArray(newData)
                ? newData.map((record) => record.id)
                : [];

            setSelectedRowKeys(allRowKeys);
        } else {
            setSelectedRowKeys([]);
        }
    };

    return {
        handleCheckboxChange,
        handleSelectAllChange
    }
}

export default useCheckbox