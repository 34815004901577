import ImageWithBasePath from '../../general/ImageCustom';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActionService from '../../../core/services/actions';
import { useTranslation } from 'react-i18next';
import ModalCopyAddress from './modal/ModalCopyAddress';
import ModalShare from './modal/ModalShare';
import LayerLoading from '../../general/LayerLoading';
import ModalConvertReceipt from './modal/ModalConvertReceipt';
import { getSubdomain, showErrorMessage, showSuccessMessage } from '../../../util/util';
import ModalSmsMessage from './modal/ModalSmsMessage';
import ModalExportFee from './modal/ModalExportFee';
import ModalPrivate from './modal/ModalPrivate';
import ModalGrantPortal from './modal/ModalGenerateFee';
import ModalGenerateFee from './modal/ModalGenerateFee';
import ModalExportPaidFee from './modal/ModalExportPaidFee';

const ButtonIcon = ({ onClick, iconSrc, children, className }) => {
  return (
    <button type='button' onClick={onClick} className={`size-8 p-2 border rounded-lg bg-white border-[#DEE7DD] cursor-pointer select-none ${className}`}>
      {iconSrc && <ImageWithBasePath src={iconSrc} />}
      {children}
    </button>
  );
};

const ButtonAction = ({ onClick, iconSrc, children, className, isLoading }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      disabled={isLoading}
      className={`flex w-full rounded-lg px-4 py-2 hover:bg-[#eeecec] font-normal text-sm text-[#121212] tracking-[2%] text-start ${isLoading ? 'opacity-60 cursor-wait' : ''} ${className}`}
    >
      {iconSrc && <ImageWithBasePath src={iconSrc} />}
      {children}
    </button>
  );
};

const LeftToolbar = (props) => {
  const { t } = useTranslation()
  const { isShow, actionsPrint, title, subTitle, actions, funcReset, model } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const detailId = parseInt(searchParams.get('id'))
  const [isOpenModal, setIsOpenModal] = useState({
    status: false,
    type: null,
    id: null
  });
  const [isOnActions, setOnActions] = useState(false);
  const handleCloseActions = () => {
    setOnActions(false)
    setIsOpenModal({ status: false, type: null, id: null })
  }
  const actionRef = useClickOutside(handleCloseActions);
  const [loading, setLoading] = useState(false);

  const url_icon = JSON.parse(sessionStorage.getItem("url_icon")) ?? {};
  const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon")) ?? {};
  const existIcon = url_icon?.url_icon ? url_icon : parent_icon;

  const updateIdInSearchParams = (newId) => {
    searchParams.set('id', newId);
    setSearchParams(searchParams);
  };

  const downloadFile = async (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadPDF = async (data, filename) => {
    const blob = await new Blob([data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    downloadFile(url, filename)
  }

  const renderSubmenu = () => {
    const handleClick = async ({ id }) => {
      if (!id) return showErrorMessage('No such report')
      handleCloseActions()
      setLoading(true)
      try {
        const res = await ActionService.getPrintReportName({ ids: id })
        const res1 = await ActionService.print({ ids: detailId, report: res[0]?.report_name })
        downloadPDF(res1, 'invoice.pdf')
      } catch (err) {
        showErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    }

    return (
      <div className={`left-[calc(100%+10px)] hidden group-hover:block top-0 absolute shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-2xl p-2 border border-[#E5E5E5] z-[100]`}>
        <div className='absolute top-0 -left-5 w-10 h-full bg-transparent'></div>
        {actionsPrint && actionsPrint.length > 0 ? actionsPrint.map((action, index) => {
          return (
            <ButtonAction key={'button-action-submenu-' + index} onClick={() => handleClick({ id: action?.id })}>
              {action?.name}
            </ButtonAction>
          )
        }) : <div className='text-center text-sm text-[#121212]'>Không có dữ liệu</div>}
      </div>
    )
  }

  const renderMoreAction = () => {
    const modalType = {
      506: 'copy',
      300: 'share',
      341: 'convert',

      249: 'sms',
      608: 'export-fee',
      609: 'export-paid-fee',
      610: 'generate-fee',
      185: 'private',
      241: 'grant-portal',
    }

    const resultLoadAction = {
      "ir.actions.server": (actionId) => handleRunAction(actionId),
      "ir.actions.act_window": (actionId) => handleOpenModal(actionId),
    }

    const resultRunAction = {
      "ir.actions.act_url": (_, url) => downloadFile(`${process.env.REACT_APP_DOMAIN}${url}&db=${getSubdomain()}`, 'contact.vcf'),
      "ir.actions.act_window": (actionId) => handleOpenModal(actionId),
    }

    const handleOpenModal = async (actionId) => {
      setIsOpenModal(prev => ({ ...prev, type: modalType[actionId], status: true, id: detailId }))
    }

    const handleRunAction = async (id) => {
      if (!id) return showErrorMessage('No such report')
      setLoading(true)
      try {
        const res = await ActionService.runAction({ idAction: id, idDetail: detailId, model: model })
        resultRunAction[res?.result?.type](id, res?.result?.url)
        console.log('>>>res', res)
      } catch (err) {
        showErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    }

    const handleGetLoadAction = async (id) => {
      if (!id) return showErrorMessage('No such report')
      handleCloseActions()
      setLoading(true)
      try {
        const res = await ActionService.loadAction({ idAction: id, idDetail: detailId, model: model })
        resultLoadAction[res?.result?.type](id)
      } catch (err) {
        showErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    }

    return (
      <div>
        {actions && actions.length > 0 && <div className='border-t pt-2'>
          {actions.map((action, index) => {
            return (
              <ButtonAction key={'button-action-more-' + index} onClick={() => handleGetLoadAction(action?.id)}>
                {action.name}
              </ButtonAction>
            )
          })}
        </div>
        }
        {isOpenModal?.status && (
          <>
            {isOpenModal.type === 'copy' && <ModalCopyAddress setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'share' && <ModalShare setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'convert' && <ModalConvertReceipt setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}

            {isOpenModal.type === 'sms' && <ModalSmsMessage setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'export-fee' && <ModalExportFee setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'export-paid-fee' && <ModalExportPaidFee setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'generate-fee' && <ModalGenerateFee setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'private' && <ModalPrivate setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
            {isOpenModal.type === 'grant-portal' && <ModalGrantPortal setIsOpenModal={setIsOpenModal} id={isOpenModal?.id} />}
          </>
        )}
      </div>
    )
  }

  const renderActions = () => {
    const handleDuplicate = async () => {
      handleCloseActions()
      setLoading(true)
      try {
        const res = await ActionService.duplicateModel({ model: model, ids: [detailId] })
        if (res) {
          showSuccessMessage(t('successs_duplicate'))
          updateIdInSearchParams(res[0]?.[0])
        }
      } catch (err) {
        showErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    }

    return (
      <div
        ref={actionRef}
        className='group-actions flex gap-2 h-full items-end'>
        <div onClick={() => setOnActions(!isOnActions)} className='size-8 border rounded-lg bg-white border-[#DEE7DD] cursor-pointer select-none relative flex justify-center items-center'>
          <ImageWithBasePath src="assets/img/setting.svg" width={16} height={16} className="w-4 h-4" />

          <div onClick={e => e.stopPropagation()} className={`${!isOnActions ? "hidden" : 'z-[100] absolute top-[calc(100%+10px)] start-0'} shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-2xl p-2 border border-[#E5E5E5] `}>
            <ButtonAction className={'group'}>
              <div className='flex w-full gap-3'>
                <ImageWithBasePath src='assets/img/icons/printer-2.svg' />
                <span className='font-normal text-sm text-[#121212] tracking-[2%]'>In</span>
              </div>
              <ImageWithBasePath src='assets/img/icons/arrow-right.svg' />
              {renderSubmenu()}
            </ButtonAction>

            <ButtonAction onClick={handleDuplicate}>
              <div className='flex w-full gap-3'>
                <ImageWithBasePath src='assets/img/icons/copy.svg' />
                <span className='font-normal text-sm text-[#121212] tracking-[2%]'>Nhân bản</span>
              </div>
            </ButtonAction>

            {renderMoreAction()}
          </div>
        </div>

        <ButtonIcon onClick={funcReset} iconSrc="assets/img/icons/reset.svg" />
      </div >
    )
  };

  const renderFormAction = () => {
    const handleClick = async () => {
      // TODO: Implement logic for form submission
    }

    return (
      <div className='relative'>
        <button
          ref={actionRef}
          onClick={() => setOnActions(!isOnActions)}
          className='relative w-6 h-6 flex items-center justify-center'
        >
          <ImageWithBasePath src='assets/img/setting.svg' className='h-4 w-4' alt='setting' width={16} height={16} />
        </button>

        <div className={`${!isOnActions ? "hidden" : 'z-[100] absolute top-[calc(100%+10px)] start-0 '} shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-2xl p-2 border border-[#E5E5E5] `}>
          <ButtonAction onClick={handleClick} className={'group'}>
            <div className='flex w-full gap-3'>
              <ImageWithBasePath src='assets/img/icons/printer-2.svg' />
              <span className='font-normal text-sm text-[#121212] tracking-[2%]'>Import dữ liệu</span>
            </div>
          </ButtonAction>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='add-item flex items-center gap-[8px] h-full order-1 flex-1'>
        <div className='page-title flex items-center h-full'>
          <div className="icon me-2 md:me-4 p-2 border border-[#E2E4E9] rounded-full flex justify-center items-center bg-primary w-12 h-12">
            <img
              src={existIcon?.url_icon && existIcon?.url_icon?.startsWith("http") ? existIcon.url_icon : process.env.REACT_APP_DOMAIN + existIcon.url_icon}
              width={20}
              height={20}
              alt="img"
            />
          </div>
          <div className={`flex ${isShow ? "gap-6 items-end" : "gap-2 items-center"}`}>
            <div className="group-title">
              <div className='title text-base md:text-xl tracking-wide text-dark font-semibold whitespace-nowrap'>{title?.toUpperCase()}</div>
              <div className='sub-title text-xl tracking-wide font-normal text-primary whitespace-nowrap'>{subTitle}</div>
            </div>
            {isShow ? renderActions() : renderFormAction()}
          </div>
        </div>
      </div>

      <LayerLoading loading={loading} />
    </>
  );
};

export default LeftToolbar;