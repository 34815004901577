import { callPath } from '../../../util/constant/endpoint'
import requests from '../AxiosClient'

const ActionService = {
  callButton: async ({ resModel, ids = [], context, method }) => {
    const jsonData = {
      model: resModel,
      method: method,
      ids: ids,
      with_context: context,
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getAcTionDetail: async ({ resModel, ids = [], context, method }) => {
    const jsonData = {
      model: resModel,
      method: method,
      ids: ids,
      with_context: context,
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

export default ActionService
