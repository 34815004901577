import React, { memo } from 'react';

const Option = memo(({ label, isActive, isFirst, isLast }) => {
  return (
    <label
      className={`relative flex-shrink-0 cursor-default py-[10px] px-8 font-semibold 
        ${isActive ? 'bg-primary text-white' : 'bg-[#F1F6F1] text-[#121212]'}
        ${isFirst ? 'rounded-l-xl ' : 'rounded-none'} 
        ${isLast ? 'rounded-r-xl' : 'rounded-none'}
      `}
    >
      <input
        type='radio'
        checked={isActive}
        className='hidden'
        disabled
      />
      {label}
      {!isLast && (
        <div
          className={`absolute top-[5px] z-[2] -right-4 w-[30px] h-[30px] border-t-4 border-r-4 border-white transform rotate-45 
            ${isActive ? 'bg-primary' : 'bg-[#F1F6F1]'}
          `}
        ></div>
      )}
    </label>
  );
});

export default Option;