import Cookies from 'js-cookie';
import PropTypes from 'prop-types'; // Add this import
import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieStorage } from '../lib/Cookies';
import useHeaderStore from '../../store/header';
export const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState('vi')
  const [isUpdate, setIsUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTest, setIsLoadingTest] = useState(false)
  const { setRootContext } = useHeaderStore()

  const handleLanguageChange = (lang) => {
    Cookies.set('i18next', lang, {
      sameSite: 'None',
      secure: true,
    })
    i18n.changeLanguage(lang)
    setLang(lang)
  }

  useEffect(() => {
    const lang = Cookies.get('i18next')
    const removeRegion = (langCode) => {
      const updatedLang = langCode.split('-')[0]
      return updatedLang
    }
    const updatedLang = removeRegion(lang)
    setLang(updatedLang)
    CookieStorage.setLanguage(updatedLang)
  }, [lang])

  return (
    <SidebarContext.Provider
      value={{
        handleLanguageChange,
        lang,
        isUpdate,
        setIsUpdate,
        isLoading,
        setIsLoading,
        setIsLoadingTest,
        isLoadingTest
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired, // Add this
}
