import React, { useState } from 'react';
import { FaChevronDown, FaTimes } from 'react-icons/fa';
import { IoCheckmark } from 'react-icons/io5';

// const options = [
//   { value: 'Azure Interior', label: 'Azure Interior' },
//   { value: 'Brandon Freeman', label: 'Brandon Freeman' },
//   { value: 'Lily Chen', label: 'Lily Chen' },
//   { value: 'Chris Lee', label: 'Chris Lee' },
// ];

const RecipientInput = ({ listUser, recipients, setRecipients, getListUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // const [searchTerm, setSearchTerm] = useState('');
  // const filteredOptions = options.filter((option) =>
  //   option.label.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const toggleOption = (option) => {
    setRecipients((prev) =>
      prev.find((item) => item.value === option.value)
        ? prev.filter((item) => item.value !== option.value)
        : [...prev, option]
    );
  };

  const isOptionSelected = (option) =>
    recipients.some((item) => item.value === option.value);

  const formatOption = listUser.map((option) => ({ value: option.id, label: option.name }))

  return (
    <div className="relative w-full">
      <h1 className="font-medium mb-1">Người nhận</h1>

      {/* Input Box */}
      <div
        className="border border-gray-300 rounded-lg p-2 cursor-pointer flex items-center justify-between"
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      >
        <div className="flex flex-wrap gap-2 items-center">
          {recipients.length > 0 ? (
            recipients.map((option) => (
              <span
                key={option.value}
                className="flex items-center bg-green-500 text-white px-3 py-1 rounded-full text-sm border"
              >
                {option.label}
                <button
                  className="ml-1 text-white focus:outline-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOption(option);
                  }}
                >
                  <FaTimes size={12} />
                </button>
              </span>
            ))
          ) : (
            <span className="text-gray-500">Thêm người nhận</span>
          )}
        </div>
        {/* Dropdown Arrow Icon */}
        <FaChevronDown
          className={`ml-2 text-gray-500 transition-transform duration-200 ${isDropdownOpen ? 'rotate-180' : ''
            }`}
        />
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 max-h-48 overflow-y-auto">
          {formatOption.length > 0 ? (
            <>
              {formatOption.map((option) => (
                <div
                  key={option.value}
                  onClick={() => toggleOption(option)}
                  className={`cursor-pointer flex items-center gap-2 px-4 py-2 hover:bg-gray-100 hover:!text-black ${isOptionSelected(option) ? ' !text-black font-bold' : ''
                    }`}
                >
                  {isOptionSelected(option) && <IoCheckmark size={20} />}
                  {option.label}
                </div>
              ))}

              {formatOption.length < 999 && (
                <button onClick={() => getListUser(9999)} className="cursor-pointer text-green-500 font-bold w-full flex items-center gap-2 px-4 py-2 hover:bg-gray-100 ">Tìm kiếm thêm</button>
              )}
            </>
          ) : (
            <div className="px-4 py-2 text-gray-500">Không tìm thấy</div>
          )}
        </div>
      )}
    </div>
  );
};

export default RecipientInput;
