export const dashboard_recentproduct = () => ({
  type: 'Dashbaord_RecentProduct',
})
export const menu_focus = (payload) => ({
  type: 'Menu_Focus',
  payload,
})
export const setdashboard_recentproduct = (payload) => ({
  type: 'Dashbaord_RecentProduct',
  payload,
})
export const dashboard_expiredproduct = () => ({
  type: 'Dashbaord_ExpiredProduct',
})
export const setdashboard_expiredproduct = (payload) => ({
  type: 'Dashbaord_ExpiredProduct',
  payload,
})
export const saleshdashboard_recenttransaction = () => ({
  type: 'Salesdashbaord_ExpiredProduct',
})
export const setsaleshdashboard_recenttransaction = (payload) => ({
  type: 'Salesdashbaord_ExpiredProduct',
  payload,
})
export const toogleHeader_data = () => ({ type: 'toggle_header' })
export const setToogleHeader = (payload) => ({
  type: 'toggle_header',
  payload,
})
export const invoicereport_data = () => ({ type: 'Invoicereport_Data' })
export const setinvoicereport_data = (payload) => ({
  type: 'Invoicereport_Data',
  payload,
})
export const rolesandpermission_data = () => ({
  type: 'Rolesandpermission_data',
})
export const setrolesandpermission_data = (payload) => ({
  type: 'Rolesandpermission_data',
  payload,
})
export const deleteaccount_data = () => ({ type: 'Deleteaccount_data' })
export const setdeleteaccount_data = (payload) => ({
  type: 'Deleteaccount_data',
  payload,
})
export const setLayoutChange = (payload) => ({
  type: 'Layoutstyle_data',
  payload,
})
export const setsettings_data = (payload) => ({
  type: 'Settings_data',
  payload,
})

export const set_menu_focus = (payload) => ({
  type: 'Menu_focus',
  payload,
})
