export const stateType = {
  posted: {
    name: 'Posted',
    type: 'success',
  },
  draft: {
    name: 'Draft',
    type: 'info',
  },
}

export const paymentType = {
  paid: {
    name: 'Paid',
    type: 'success',
  },
  not_paid: {
    name: 'Not Paid',
    type: 'danger',
  },
}
