import { create } from 'zustand'

const useFormStore = create((set) => ({
  viewDataStore: {},
  actionDataStore: {},
  toolbarStore: {},
  isCheckField: false,
  setViewDataStore: (viewData) => set(() => ({ viewDataStore: viewData })),
  setActionDataStore: (actionData) => set(() => ({ actionDataStore: actionData })),
  setToolbarStore: (toolbar) => set(() => ({ toolbarStore: toolbar })),
  setIsCheckField: (isCheck) => set(() => ({ isCheckField: isCheck })),
}))

export default useFormStore
