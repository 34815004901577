import React, { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SidebarContext } from '../../util/context/SidebarContext'
import ButtonLoading from '../../components/general/ButtonLoading'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import { showErrorMessage, showSuccessMessage } from '../../util/util'

const ResetPassword = () => {
  const { isLoading } = useContext(SidebarContext)
  const { register, handleSubmit } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const togglePasswordVisibility = (e) => {
    e.preventDefault()
    setShowPassword((prevState) => !prevState)
  }

  const toggleConfirmPasswordVisibility = (e) => {
    e.preventDefault()
    setShowConfirmPassword((prevState) => !prevState)
  }

  const onSubmit = (data) => {
    UserService.resetPassword({ data: data, token: token })
      .then((res) => {
        showSuccessMessage('Reset password successfully')
      })
      .catch((err) => {
        showErrorMessage(err?.detail || 'Reset password failed')
      })
  }

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center">
          <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
            <div className='flex flex-col gap-3'>
              <div className='flex flex-col items-center gap-3'>
                <ImageWithBasePath
                  src={'assets/img/forgot-password/lock.svg'}
                  className="object-cover w-[32px] h-[40px]"
                />
                <div className='text-[20px] text-center font-bold text-[rgb(38,38,38)]'>Mật khẩu mới</div>
              </div>

              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  Mật khẩu mới
                </label>
                <div className='relative'>
                  <input type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Vui lòng nhập mật khẩu mới' />
                  <div onClick={togglePasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!showPassword ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  Nhập lại mật khẩu mới
                </label>
                <div className='relative'>
                  <input type={showConfirmPassword ? 'text' : 'password'}
                    {...register('confirmPassword')}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Vui lòng nhập lại mật khẩu mới' />
                  <div onClick={toggleConfirmPasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!showConfirmPassword ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            <ButtonLoading loading={isLoading} content={'Lưu'} className='text-[16px] rounded font-semibold py-3 px-4 text-[rgba(255,255,255,1)]' />
          </div>
        </form>
      </div>
    </div>

  )
}

export default ResetPassword
