import React from 'react';

const HtmlField = ({
    type,
    name,
    methods,
    readonly,
    placeHolder,
    required,
    savedDataDetail = {},
    hasBorder = true,
    invisible,
    value,
    isForm,
    defaultValue,
    handleOnchange
}) => {
    // const formProps = methods ? methods.register(name, {
    //     onChange: (e) => {

    //         methods.setValue(e.target.name, e.target.value, {
    //             shouldDirty: true
    //         });
    //         handleOnchange(e.target.name, e.target.value)
    //     }
    // }) : {}

    console.log("html field", {
        type,
        name,
        methods,
        readonly,
        placeHolder,
        required,
        savedDataDetail,
        hasBorder,
        invisible,
        value,
        isForm,
        defaultValue,
        handleOnchange
    });


    const handleInput = (e) => {
        const content = e.currentTarget.textContent;
        console.log("content", content);

        // if (methods) {
        //     methods.register(name, {
        //         onChange: (e) => {
        //             methods.setValue(name, content, {
        //                 shouldDirty: true
        //             });
        //         }
        //     })
        // }
        // methods.setValue(name, content, {
        //     shouldDirty: true
        // });
        handleOnchange(name, content);
    };

    return (
        <div
            onInput={handleInput}
            contentEditable="true"
            dangerouslySetInnerHTML={{ __html: value }}
            className={` ${!isForm ? "!bg-transparent focus-within:!border-none" : "field"}`}
        >
        </div>
    )
}

export default HtmlField
