import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, Outlet, useLocation } from 'react-router-dom'
import { pagesRoute, publicRoutes } from './router.link'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { CookieStorage } from '../util/lib/Cookies'
import Header from '../components/header'
import Sidebar from '../components/header/sidebar/SideBar'
import MetadataManager from '../metadata/MetadataManager'
import StoreService from '../core/services/company/StoreService'
import { MetaProvider, useMetaContext } from './MetaContext'
import '../style/css/color.css'
import { useTranslation } from 'react-i18next'
import useScrollToTop from '../hooks/useScrollToTop'
import useHeaderStore from '../store/header'
import useThemeStore from '../store/theme'

const HeaderLayout = () => {
  const data = useSelector((state) => state.toggle_header)
  const menuFocus = useSelector((state) => state.menu_focus)
  const { layoutStyling } = useThemeStore()
  const { openTouch, hover } = useMetaContext()
  const [toggleMenuMobile, setToggleMenuMobile] = useState(false)

  return (
    <div className={`main-wrapper ${data ? 'header-collapse' : ''} flex`}>
      <div
        className={layoutStyling !== "horizontal" && `transitionSb relative h-full min-h-screen ${openTouch || hover ? 'lg:w-[260px] max-w-[260px] duration-700' : 'lg:w-[80px] duration-500'} transition-[width] ease-in-out block w-0`}
        style={{ flexShrink: 0, }}
      >
        <div
          className={layoutStyling !== "horizontal" && `sidebar-wrapper trasitionSb ${toggleMenuMobile} fixed left-0 top-0 z-40 h-[100vh] transition-all duration-700 ease-in-out ${openTouch || hover ? 'duration-900 w-[260px] transition-all ease-in-out' : 'w-[80px]'} ${toggleMenuMobile ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}`}
        >
          <Sidebar setToggleMenuMobile={setToggleMenuMobile} />
        </div>
      </div>
      <div className={`grow overflow-hidden`}>
        <Header setToggleMenuMobile={setToggleMenuMobile} />
        <Outlet key={menuFocus} />
      </div>
    </div>
  )
}
const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header)
  const location = useLocation()
  const { setRootContext } = useHeaderStore()
  const { setLayoutStyling } = useThemeStore()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  useScrollToTop();

  useEffect(() => {
    setLayoutStyling(process.env.REACT_APP_SIDEBAR_TYPE ?? "default")
    Cookies.set('i18next', 'vi')
    i18n.changeLanguage('vi')

    const hasAccessToken = () => {
      const token = CookieStorage.getAccessToken()
      return !!token
    }

    if (!hasAccessToken() && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') {
      navigate('/signin')
    } else {
      if (location.pathname === '/') {
        navigate('/list?vid=332&model=account.move')
      }
    }
  }, [])

  const Authpages = () => (
    <div className={`${data ? 'header-collapse' : ''}`}>
      <Outlet />
    </div>
  )
  const [id, setId] = useState(null)
  const [metadata, setMetadata] = useState(null)
  useEffect(() => {
    if (metadata && metadata.primary_color) {
      console.log(
        "document.documentElement.style.setProperty('--primary', metadata.primary_color);",
        // document.documentElement.style.setProperty('--primary', metadata.primary_color)
        document.documentElement.style.setProperty('--primary', '#0B701D')
      )
      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
    } else {
      // Set a default color if primary_color is not available
      // document.documentElement.style.setProperty("--primary", "#000000"); // Example fallback color
    }
  }, [metadata])

  const [loadingImport, setLoadingImport] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorData, setErrorData] = useState()
  const [openTouch, setOpenTouch] = useState(true)
  const [hover, setHover] = useState(false)
  const accessToken = CookieStorage.getAccessToken()
  const fetchCurrentCompany = async () => {
    await StoreService.getCurrentCompany().then((res) => {
      setId(res?.current_company_id)
      setRootContext({ allowed_company_ids: [res?.current_company_id] })
    })
  }
  const handleLoadingImport = (action) => {
    setLoadingImport(action)
  }
  const getUserCompany = async (companyId) => {
    await StoreService.getUserCompany(companyId).then((res) => {
      setMetadata(res?.records[0])
    })
  }

  useEffect(() => {
    accessToken && fetchCurrentCompany()
    if (id && accessToken) {
      getUserCompany(id)
    }
  }, [id, accessToken])
  return (
    <div className='App'>
      <MetaProvider
        metadata={metadata}
        errorData={errorData}
        setErrorData={setErrorData}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        loadingImport={loadingImport}
        setLoadingImport={handleLoadingImport}
        openTouch={openTouch}
        setOpenTouch={setOpenTouch}
        setHover={setHover}
        hover={hover}
      >
        {metadata && <MetadataManager metadata={metadata} />}
        <Routes>
          <Route path={'/'} element={<HeaderLayout />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
          <Route path={'/'} element={<Authpages />}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        </Routes>
      </MetaProvider>
    </div>
  )
}
export default AllRoutes
