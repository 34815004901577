import React, { useEffect } from 'react'

function MetadataManager({ metadata }) {
  useEffect(() => {
    // Cập nhật title
    if (metadata.display_name) {
      document.title = metadata.display_name
    }

    // Cập nhật description
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute('content', metadata.display_name)
    } else {
      const newMetaDescription = document.createElement('meta')
      newMetaDescription.name = 'description'
      newMetaDescription.content = metadata.display_name
      document.head.appendChild(newMetaDescription)
    }

    // Cập nhật icon
    const updateIcon = (rel, href) => {
      let linkIcon = document.querySelector(`link[rel="${rel}"]`)
      if (linkIcon) {
        linkIcon.href = href
      } else {
        linkIcon = document.createElement('link')
        linkIcon.rel = rel
        linkIcon.href = href
        document.head.appendChild(linkIcon)
      }
    }

    if (metadata.logo) {
      // Cập nhật cả favicon và apple-touch-icon
      updateIcon('icon', metadata.logo)
      updateIcon('apple-touch-icon', metadata.logo)

      // Xóa favicon.ico nếu tồn tại
      const faviconLink = document.querySelector('link[href="favicon.ico"]')
      if (faviconLink) {
        faviconLink.remove()
      }
    }
  }, [metadata])

  return null // Component này không render gì cả
}

export default MetadataManager
