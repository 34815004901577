import { create } from 'zustand'

const useExcelStore = create((set) => ({
  dataParse: null,
  idFile: null,
  isFileLoaded: false,
  setDataParse: (viewData) => set(() => ({ dataParse: viewData })),
  setIdFile: (viewData) => set(() => ({ idFile: viewData })),
  setIsFileLoaded: (isLoaded) => set(() => ({ isFileLoaded: isLoaded })),
  setNullData: (() => set(() => ({ dataParse: null })))
}))

export default useExcelStore
