import React, { useEffect } from 'react';

const RadioButtonGroup = ({
  name,
  readonly,
  required,
  setValue,
  placeholder,
  invisible,
  methods,
  selection,
  handleOnchange,
  value
}) => {

  const formProps = methods ? methods.register(name, {
    onChange: (e) => {
      methods.setValue(e.target.name, e.target.value, {
        shouldDirty: true
      });
      handleOnchange(e.target.name, e.target.value)
    }
  }) : {}
  useEffect(() => {

    if (selection?.length > 0) {
      if (setValue) setValue(name, selection?.[0]?.[0])
    }
  }, [selection, name, setValue])

  return (
    <div className='flex flex-col gap-[10px] pb-4'>
      {selection?.map((select, index) => (
        <div className='flex items-center gap-1' key={select[0]}>
          <input
            type='radio'
            {...formProps}
            id={select[0]}
            value={select[0]}
            readOnly={readonly}
            required={!invisible && required}
            placeholder={placeholder}
            className='custom-radio'
          />
          <label htmlFor={select[0]}>{select[1]}</label>
        </div>
      ))}
    </div>
  )
}

export default RadioButtonGroup
