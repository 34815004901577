import React from 'react';

const CharField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods = null,
  isForm,
  defaultValue,
  handleOnchange
}) => {
  const formProps = methods ? methods.register(name, {
    onBlur: (e) => {
      if (readonly || (value === e.target.value)) return;
      methods.setValue(e.target.name, e.target.value, {
        shouldDirty: true
      });
      handleOnchange(e.target.name, e.target.value)
    }
  }) : {}

  if (!isForm) return (<span>{value || defaultValue}</span>)

  return (
    <input
      {...formProps}
      defaultValue={value || defaultValue}
      type="text"
      readOnly={readonly}
      disabled={readonly}
      placeholder={placeholder}
      required={!invisible && required}
      style={invisible ? { display: 'none' } : {}}
      className={` ${!isForm ? "!bg-transparent focus-within:!border-none" : "field"}`}
      onChange={(e) => { e.preventDefault() }}
    />
  )
}

export default CharField
