import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'

const QrCode = ({ value, size = 128, bgColor = '#ffffff', fgColor = '#000000', level = 'L', string }) => {
  return (
    <div id='qr-code'>
      <QRCodeCanvas value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} />
    </div>
  )
}

export default QrCode
