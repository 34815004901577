import React from 'react';
import { stringToColor } from '../../util/util';
import { Tooltip } from 'react-tooltip';

const Avatar = ({ fullName, id, size = 26, textColor = "#fff" }) => {
  const initial = fullName ? fullName.charAt(0).toUpperCase() : '';

  const bgColor = stringToColor(fullName, id);

  const avatarStyle = {
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: bgColor,
    color: textColor,
    fontSize: `${size / 2.5}px`,
  };

  return <div data-tooltip-id={"avatar-widget-id-" + id} style={avatarStyle} className='uppercase font-medium rounded-full flex items-center justify-center cursor-pointer'>
    {initial}
    <Tooltip
      id={"avatar-widget-id-" + id}
      place="top"
      content={fullName}
    />
  </div>;
};


const InfoWidget = (props) => {
  const { value } = props
  console.log("🚀 ~ InfoWidget ~ value:", value)
  if (!value || (Array.isArray(value) && value?.length === 0)) {
    return <></>
  }

  return (
    <div className='group relative flex items-center gap-1 rounded-lg transition-shadow duration-300'>
      {
        value?.length > 1 ? value?.map((item) => (
          <Avatar key={item.id} id={item.id} fullName={item?.display_name} />
        )) : <div className='flex gap-2 items-center'>
          <Avatar id={value[0]?.id} fullName={value[0]?.display_name} />
          <span className="capitalize">{value[0]?.display_name}</span>
        </div>
      }
    </div>
  )
}

export default InfoWidget
