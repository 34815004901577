import React from 'react'
import { useTranslation } from 'react-i18next'
import { PuffLoader } from 'react-spinners'

const LayerLoading = ({ loading }) => {
    const { t } = useTranslation()

    if (!loading) return null
    return (
        <div className="fixed inset-0 z-[999] flex flex-col gap-4 items-center justify-center bg-black/40 backdrop-blur-sm">
            <PuffLoader size={60} color={"green"} loading={loading} />
            <div className='text-white text-2xl font-bold shadow-md'>{t("loading")}</div>
        </div>
    )
}

export default LayerLoading