import { useState } from 'react';

const useCopy = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      setIsCopied(text)
      return await navigator.clipboard.writeText(text)
    } else {
      // Fallback for browsers that do not support navigator.clipboard
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.position = 'fixed' // Avoid scrolling to the bottom of the page in MS Edge.
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
        setIsCopied(text)
      } finally {
        document.body.removeChild(textArea)
      }
    }
  }

  return [isCopied, copyTextToClipboard];
};

export default useCopy;
