import React from 'react'
import { paymentType, stateType } from '../../util/constants'
import { useTranslation } from 'react-i18next'

const typeStyles = {
  primary: 'bg-blue-500 text-white',
  secondary: 'bg-lime-600 text-white',
  danger: 'bg-[#FDEDF0]  text-[#DF1C41]',
  info: 'bg-[rgba(5,152,237,0.12)] text-[rgb(5,152,237)]',
  success: 'bg-[rgba(46,181,83,0.1)] text-[rgb(46,181,83)]',
  warning: 'bg-[#FEF3EB] text-[#F17B2C]',
  dark: 'bg-gray-800 text-white text-white',
  light: 'bg-white text-gray-800 shadow-md',
}

const ButtonBadge = (props) => {
  const { t } = useTranslation()
  const { defaultValue, name } = props
  const typeBadge = (name === "payment_state") ? paymentType : name === "state" ? stateType : null
  const baseStyle =
    'rounded-md text-xs px-[0.82rem] py-1 text-center font-medium cursor-pointer transition duration-300 ease-in-out transform'
  const buttonClass = `${baseStyle} ${typeStyles[typeBadge[defaultValue]?.type]}`

  return (<div className={buttonClass}>{t(typeBadge[defaultValue]?.name)}</div>)
}

export default ButtonBadge
