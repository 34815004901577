import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

const TextAreaField = ({ methods, name, readonly, placeHolder, required, invisible, isForm, value, handleOnchange }) => {
  const formProps = methods ? methods.register(name, {
    onBlur: (e) => {

      methods.setValue(e.target.name, e.target.value, {
        shouldDirty: true
      });
      handleOnchange(e.target.name, e.target.value)
    }
  }) : {}

  if (!isForm) {
    return <span>{value}</span>
  }

  return (
    <textarea
      {...formProps}
      className={`textarea-field h-fit w-full cursor-pointer rounded-lg border-[1px] border-solid border-[#E4E4E7] bg-[#fff] p-2 ${isForm && 'field'}`}
      readOnly={readonly}
      disabled={readonly}
      placeholder={placeHolder}
      required={!invisible && required}
      onChange={(e) => { e.preventDefault() }}
    />
  )
}

export default TextAreaField
