import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { X } from 'react-feather'
import RecipientInput from '../tag/RecipientInput'
import { useSearchParams } from 'react-router-dom'
import ActionService from '../../../../core/services/actions'
import { showErrorMessage } from '../../../../util/util'

const ModalShare = ({ setIsOpenModal, id }) => {
    const [recipient, setRecipient] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [notes, setNotes] = useState('');
    const [searchParams,] = useSearchParams();
    const detailId = parseInt(searchParams.get('id'))

    const handleSubmit = async () => {
        try {
            const res = await ActionService.createRecord({ id: detailId, ids: recipient.map(item => item?.value), note: notes?.toString() })
            const res1 = await ActionService.sendMail({ ids: res })
        } catch (error) {
            showErrorMessage(error?.message)
        } finally {
            onClose();
        }
    };

    const handleClose = () => {
        setIsOpenModal(prev => ({ ...prev, status: false }))
    }

    const getListUser = async (limit) => {
        try {
            const res = await ActionService.getListUserModel({ limit })
            setListUser(res?.records)

        } catch (error) {
            showErrorMessage(error?.message)
        }
    }

    useEffect(() => {
        if (id) {
            getListUser(10)
        }
    }, [id])

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleClose}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto'>
                            <div className='relative z-[1] mx-auto my-[88px] p-4 flex flex-col gap-2 max-w-[1000px] transform rounded-3xl bg-[#FFF]'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div className='text-[20px] font-semibold'>
                                        Chia sẻ tài liệu
                                    </div>
                                    <button onClick={handleClose}>
                                        <X size={20} />
                                    </button>
                                </div>

                                <div className="rounded-lg pt-4 pb-7 w-full">
                                    <div className="mb-4">
                                        <RecipientInput listUser={listUser} recipients={recipient} setRecipients={setRecipient} getListUser={getListUser} />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block font-medium mb-1">Ghi chú</label>
                                        <input
                                            type="text"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            placeholder="Add notes"
                                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-indigo-200"
                                        />
                                    </div>

                                    <div className='flex gap-3 w-full justify-end py-2'>
                                        <button
                                            onClick={handleSubmit}
                                            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                        >
                                            Gửi
                                        </button>
                                        <button
                                            onClick={handleClose}
                                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
                                        >
                                            Hủy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalShare