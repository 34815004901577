import React, { useState } from 'react';
import { convertFloatToTime, convertTimeToFloat, isFloat, isNumber } from '../../util/util';

const FloatTime = ({ name, methods, value }) => {
    const formatValue = value ?? 0
    const [inputValue, setInputValue] = useState(convertFloatToTime(formatValue));

    const handleChangeView = (e) => {
        const input = e.target.value;
        setInputValue(input);
        methods?.setValue(name, convertTimeToFloat(input), {
            shouldDirty: true
        });
    };

    const handleBlur = () => {
        if (isFloat(inputValue) || isNumber(inputValue)) {
            const floatVal = parseFloat(inputValue);
            const timeString = convertFloatToTime(floatVal);
            setInputValue(timeString);
            methods?.setValue(name, floatVal, {
                shouldDirty: true
            });
        }
    };

    return (
        <div className='relative'>
            <input
                type="text"
                {...methods?.register(name)}
                className='w-11 bg-transparent invisible'
            />
            <input
                type="text"
                value={inputValue}
                onChange={handleChangeView}
                onBlur={handleBlur}
                defaultValue={convertFloatToTime(value)}
                className='absolute inset-0'
            />
        </div>
    );
};

export default FloatTime;