import React, { createContext, useContext } from 'react'

const MetaContext = createContext()

export const MetaProvider = ({ children, ...props }) => {
  return (
    <MetaContext.Provider
      value={{
        ...props,
      }}
    >
      {children}
    </MetaContext.Provider>
  )
}
export const useMetaContext = () => useContext(MetaContext)
