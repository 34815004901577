import {
  authTokenPath,
  profilePath,
  updatePath,
  uploadFilePath,
  callPath,
  createPath,
  resetPasswordPath,
  changePasswordPath,
} from '../../../util/constant/endpoint'
import requests from '../AxiosClient'

import qs from 'qs'

const UserService = {
  login: async (body) => {
    const encodedData = qs.stringify({
      username: body.email,
      password: body.password,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    })
    return requests.post(authTokenPath, encodedData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  forgotPassword: async (body) => {
    const encodedData = qs.stringify({
      login: body.email,
      url: 'http://edu.vitrust.app/reset-password',
    })
    return requests.post(resetPasswordPath, encodedData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  resetPassword: async ({ data, token }) => {
    const encodedData = {
      token: token,
      password: data.password,
      new_password: data.confirmPassword,
    }
    return requests.post(changePasswordPath, encodedData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  profile: async () => {
    return requests.get(profilePath, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  updateProfile: async (body) => {
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  setImage: async (body, queryParams) => {
    const query = new URLSearchParams(queryParams).toString()
    return requests.post(uploadFilePath + '?' + query, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  getAll: async () => {
    const jsonData = {
      model: 'res.users',
      method: 'web_search_read',
      kwargs: {
        domain: [],
        specification: {
          name: {},
          email_formatted: {},
          id: {},
          phone: {},
          email: {},
          groups_id: { fields: { name: {}, display_name: {} } },
          image_256: {},
          complete_name: {},
          state: {},
          im_status: {},
        },
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  unlinkUser: async (body) => {
    return requests.post(callPath, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  createUser: async (body) => {
    return requests.post(createPath, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  updateUser: async (body) => {
    return requests.post(updatePath, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
}

export default UserService
